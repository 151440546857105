import Vue from 'vue';
import { AUTH_ENDPOINTS } from '@/constants/';
import app from '@/main';

const store = {
  namespaced: true,

  state: {
    token: getLocalToken() || '',
    errors: '',
  },

  mutations: {
    setToken: function (state, payload) {
      state.token = payload;
    },
  },

  actions: {
    login({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(AUTH_ENDPOINTS.LOGIN, payload).then(({ data }) => {
          const { token } = data;
          if (token) {
            dispatch('updateToken', token);
          }
          resolve();
        }).catch(() => {
          reject();
        });
      })
    },

    updateToken({ commit }, token) {
      const bearerToken = `Bearer ${token}`;
      changeAxiosAuthHeader(bearerToken);
      commit('setToken', bearerToken);
      localStorage.setItem('user-token', bearerToken);
    },

    logoutUser({ commit }) {
      removeLocalToken();
      changeAxiosAuthHeader();
      commit('setToken', '');
    },
      
    refreshToken({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(AUTH_ENDPOINTS.REFRESH_TOKEN, {
          token: state.token.replace('Bearer ', ''),
        }).then(({ data }) => {
          const { token } = data;
          dispatch('updateToken', token)
          resolve();
        }).catch(() => {
          dispatch('logoutUser');
          app.$router.push({
            name: 'login',
          });
          reject();
        })
      })
    },
  },

  getters: {
    isUserLoggedIn(state) {
      return !!(state.token)
    },
  },
};

function getLocalToken() {
  return localStorage.getItem('user-token');
}

function removeLocalToken() {
  localStorage.removeItem('user-token');
}

function changeAxiosAuthHeader(bearerToken) {
  if (bearerToken) {
    Vue.axios.defaults.headers.common['Authorization'] = bearerToken;
  } else {
    delete Vue.axios.defaults.headers.common['Authorization'];
  }
}

export default store;