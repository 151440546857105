<template>
  <div class="out-transfer-detail">
    <b-form>
      <b-row class="mb-3">
        <b-col v-for="(value, key) in currentOutTransfer" :key="key" :cols="6">
          <b-form-group :label="key">
            <b-form-input
              disabled
              :value="value"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <b-button class="mr-2" @click="goToList">Назад</b-button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'TransferDetail',

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState('transfers', ['currentOutTransfer']),
  },

  created() {
    this.getOutTransfer(this.id);
  },

  methods: {
    ...mapActions('transfers', ['getOutTransfer']),

    goToList() {
      this.$router.push({
        name: 'out-transfers-list',
      });
    }
  },
}
</script>