import Vue from 'vue';
import VueRouter from 'vue-router';
import { AuthGuard } from './auth-guard';
import Index from '@/views/Index.vue';
import LoginPage from '@/views/LoginPage.vue';
import CardsList from '@/views/cards/CardsList.vue';
import CardDetail from '@/views/cards/CardDetail.vue';
import CardCreate from '@/views/cards/CardCreate.vue';
import AccountsList from '@/views/accounts/AccountsList.vue';
import AccountDetail from '@/views/accounts/AccountDetail.vue';
import AccountCreate from '@/views/accounts/AccountCreate.vue';
import TransfersList from '@/views/transfers/TransfersList.vue';
import OutTransfersList from '@/views/transfers/OutTransfersList.vue';
import TransferDetail from '@/views/transfers/TransferDetail.vue';
import TransferCreate from '@/views/transfers/TransferCreate.vue';
import OutTransferDetail from '@/views/transfers/OutTransferDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    redirect: {
      name: 'cards-list'
    },
    beforeEnter: AuthGuard.isLoggedIn,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/cards',
    name: 'cards-list',
    component: CardsList,
    beforeEnter: AuthGuard.isLoggedIn,
    meta: {
      title: 'Card List',
    },
  },
  {
    path: '/cards/create/',
    name: 'card-create',
    component: CardCreate,
    beforeEnter: AuthGuard.isLoggedIn,
    props: true,
    meta: {
      title: 'Create Card'
    }
  },
  {
    path: '/cards/:id',
    name: 'card-detail',
    component: CardDetail,
    beforeEnter: AuthGuard.isLoggedIn,
    props: true,
    meta: {
      title: 'Card'
    }
  },
  {
    path: '/accounts',
    name: 'accounts-list',
    component: AccountsList,
    beforeEnter: AuthGuard.isLoggedIn,
    meta: {
      title: 'Account'
    }
  },
  {
    path: '/accounts/:id',
    name: 'account-detail',
    component: AccountDetail,
    beforeEnter: AuthGuard.isLoggedIn,
    props: true,
    meta: {
      title: 'Account'
    }
  },
  {
    path: '/accounts/create/',
    name: 'account-create',
    component: AccountCreate,
    beforeEnter: AuthGuard.isLoggedIn,
    props: true,
    meta: {
      title: 'Create account'
    }
  },
  {
    path: '/transfers',
    name: 'transfers-list',
    component: TransfersList,
    beforeEnter: AuthGuard.isLoggedIn,
    meta: {
      title: 'Transfer list'
    }
  },
  {
    path: '/transfers/:id',
    name: 'transfer-detail',
    component: TransferDetail,
    beforeEnter: AuthGuard.isLoggedIn,
    props: true,
    meta: {
      title: 'Transfers'
    }
  },
  {
    path: '/transfers/create/',
    name: 'transfer-create',
    component: TransferCreate,
    beforeEnter: AuthGuard.isLoggedIn,
    props: true,
    meta: {
      title: 'Create transfer'
    }
  },
  {
    path: '/out-transfers',
    name: 'out-transfers-list',
    component: OutTransfersList,
    beforeEnter: AuthGuard.isLoggedIn,
    meta: {
      title: 'Out transfer list'
    }
  },
  {
    path: '/out-transfers/:id',
    name: 'out-transfer-detail',
    component: OutTransferDetail,
    beforeEnter: AuthGuard.isLoggedIn,
    props: true,
    meta: {
      title: 'Out transfer'
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || process.env.VUE_APP_TITLE
  })
})

export default router;
