<template>
  <div class="transfers-list">
    <b-icon icon="plus-circle-fill" variant="primary" class="h1 cursor-pointer ml-auto d-block mb-4" @click="createTransfer" />
    <b-table
      :items="transfersList"
      :fields="tableFields"
      striped
      hover
      @row-clicked="goToDetail"
    >
      <template #cell(creationTimestamp)="cell">
        {{ localizedTime(cell.value) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from '@/services/dayjs';

export default {
  name: 'TransfersList',

  data: () => ({
    tableFields: [
      {
        label: 'Source type',
        key: 'sourceType',
      },
      {
        label: 'Destination type',
        key: 'destinationType',
      },
      {
        label: 'Value',
        key: 'destinationAmount',
      },
      {
        label: 'Currency dest',
        key: 'destinationCurrency',
      },
      {
        label: 'State',
        key: 'state',
      },
      {
        label: 'Tag',
        key: 'tag',
      },
      {
        label: 'Created at',
        key: 'creationTimestamp',
      },
    ]
  }),

  computed: {
    ...mapState('transfers', ['transfersList']),
  },

  created() {
    this.getTransfersList();
  },

  methods: {
    ...mapActions('transfers', ['getTransfersList']),

    goToDetail({ id }) {
      this.$router.push({
        name: 'transfer-detail',
        params: {
          id,
        }
      });
    },

    localizedTime(time) {
      return dayjs(time).format('L LTS');
    },

    createTransfer() {
      this.$router.push({
        name: 'transfer-create'
      })
    },
  }
}
</script>
