<template>
  <div id="app" class="app">
    <div class="app-container">
      <Navigation
        @logout="logout"
      />
      <div class="app-content">
        <b-container>
          <h1 v-if="showTitle" class="mb-4">{{ $route.meta.title }}</h1>
          <router-view />
        </b-container>
      </div>
    </div>
    <loader v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Navigation from '@/components/app/Navigation.vue';
import Loader from '@/components/app/Loader.vue';

export default {
  name: 'App',

  components: {
    Navigation,
    Loader,
  },

  computed: {
    ...mapState('loading', ['loading']),
    showTitle() {
      return this.$route.meta.title && !this.$route.meta.hideTitle
    }
  },

  methods: {
    ...mapActions('auth', ['logoutUser']),

    logout() {
      this.logoutUser();
      this.$router.push({
        name: 'login',
      });
    },
  },
}
</script>

<style lang="scss" src="@/assets/scss/index.scss"></style>

<style lang="scss">
.app {
  &-container {
    min-height: 100vh;
    display: grid;
    gap: 15px;
    grid-template-columns: 240px auto;
    grid-template-rows: 100px auto;
    grid-template-areas:
    "navigation header"
    "navigation content";
    
    &--withoutHeader {
      grid-template-areas:
      "navigation content"
      "navigation content";
    }
  }
  
  &-content {
    padding-top: 30px;
  }
}
</style>