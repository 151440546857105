import Vue from 'vue';
import { CREDENTIALS_ENDPOINT } from '@/constants/';

export default {
  namespaced: true,

  state: {
    credentials: {},
  },

  mutations: {
    setCredentials(state, payload) {
      state.credentials = payload;
    },
  },

  actions: {
    getCredentials({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(CREDENTIALS_ENDPOINT).then(({ data }) => {
          commit('setCredentials', data);
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
  },

  getters: {
    uiKey(state) {
      return state.credentials.uiKey;
    },

    expires(state) {
      return state.credentials.expires;
    },
    
    userAuthToken(state) {
      return state.credentials.userAuthToken;
    }
  }
}