<template>
  <div class="loader">
    <div class="loader__container">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<style lang="scss">
.loader {
  background: var(--color-bg-body-opacity);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    position: relative;
    height: 200px;
    width: 200px;
    animation: Loader 2s infinite linear;

    span {
      width: 100px;
      height: 100px;
      position: absolute;
      left: calc(50% - 50px);
      top: calc(50% - 50px);
      border: 2px solid var(--color-hover);
      border-radius: 50%;

      &:nth-child(1) {
        animation: Span-1 3s ease-in-out infinite;
      }
      &:nth-child(2) {
        animation: Span-2 3s ease-in-out infinite;
      }
      &:nth-child(3) {
        animation: Span-3 3s ease-in-out infinite;
      }
      &:nth-child(4) {
        animation: Span-4 3s ease-in-out infinite;
      }
    }
  }
}

@keyframes Loader {
  0% {transform:rotate(-0deg)}
  25%{transform:rotate(-90deg)}
  50%{transform:rotate(-180deg)}
  75%{transform:rotate(-270deg)}
  100%{transform:rotate(-360deg)}}

@keyframes Span-1 {
  0% {transform:translate(0);}
  50%{transform:translate(-200px, 0);border-color:var(--color-red)}
  100%{transform:translate(0);}}

@keyframes Span-2 {
  0%{transform:translate(0);}
  50%{transform:translate(200px, 0);border-color:var(--color-yellow)}
  100%{transform:translate(0);}}

@keyframes Span-3 {
  0%{transform:translate(0);}
  50%{transform:translate(0, -200px);border-color:var(--color-blue)}
  100%{transform:translate(0);}}

@keyframes Span-4 {
  0%{transform:translate(0);}
  50%{transform:translate(0, 200px);border-color:var(--color-mint)}
  100%{transform:translate(0);}}
</style>