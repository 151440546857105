export const NAVIGATION = [
  {
    title: 'Card List',
    route: 'cards-list',
    icon: 'list',
  },
  {
    title: 'Account list',
    route: 'accounts-list',
    icon: 'archive',
  },
  {
    title: 'Transfers list',
    route: 'transfers-list',
    icon: 'book',
  },
  {
    title: 'Out transfers list',
    route: 'out-transfers-list',
    icon: 'book',
  },
  {
    title: 'Logout',
    route: false,
    icon: 'logout',
    callback: 'logout',
  },
];
