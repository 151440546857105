<template>
  <div class="card-create">
    <b-form @submit.prevent="submit">
      <b-row class="mb-5">
        <b-col
          v-for="(value, field) in cardPrimitiveFields"
          :key="field"
          :cols="6"
        >
          <b-form-group :label="field">
            <b-input
              v-model="form[field]"
              :placeholder="`Enter field value  ${field}`"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-form-group
        label="Billing Address"
        label-class="font-weight-bold mb-3 pt-0"
      >
        <b-row>
          <b-col
            v-for="(value, field) in form.billingAddress"
            :key="field"
            :cols="6"
          >
            <b-form-group :label="field">
              <b-input
                v-model="form.billingAddress[field]"
                :placeholder="`Enter field value  ${field}`"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <b-button class="mr-2" @click="goToList">Cancel</b-button>
      <b-button variant="primary" type="submit">Save</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CardCreate',

  data: () => ({
    form: {
      billingAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
        postCode: '',
        state: ''
      },
      cardholderMobileNumber: '',
      currency: '',
      friendlyName: '',
      mode: '',
      nameOnCard: '',
      tag: '',
      parentManagedAccountId: '',
    }
  }),

  computed: {
    cardPrimitiveFields() {
      return Object.entries(this.form).reduce((fields, [key, value]) => {
        if (typeof value !== 'object') {
          fields[key] = value;
        }
        return fields;
      }, {});
    },
  },

  methods: {
    ...mapActions('cards', ['createCard']),

    submit() {
      this.createCard(this.form);
    },

    goToList() {
      this.$router.push({
        name: 'cards-list',
      })
    }
  }
}
</script>
