<template>
  <b-modal
    id="account-edit-modal"
    class="account-edit-modal"
    size="xl"
    title="Edit account"
    centered
    ok-title="Save"
    cancel-title="Cancel"
    @ok="edit($event)"
  >
    <account-edit v-if="accountId" :id="accountId" :accountModel="accountModel" />
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AccountEdit from './AccountEdit.vue';

export default {
  name: 'AccountEditModal',

  components: {
    AccountEdit
  },

  props: {
    accountId: {
      type: String,
      required: true,
    }
  },

  computed: {
    ...mapState('accounts', ['currentAccount']),

    accountModel() {
      return {
        friendlyName: this.currentAccount.friendlyName,
        tag: this.currentAccount.tag,
      }
    }
  },

  methods: {
    ...mapActions('accounts', [ 'editAccount', 'getList' ]),

    edit(event) {
      this.editAccount({
        id: this.accountId,
        payload: this.accountModel,
        event,
      }).then(() => {
        this.getList();
      });
    }
  }
}
</script>
