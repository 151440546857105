<template>
  <div class="accounts-list">
    <b-icon icon="plus-circle-fill" variant="primary" class="h1 cursor-pointer ml-auto d-block mb-4" @click="createAccount" />

    <b-table
      :items="list"
      :fields="tableFields"
      striped
      hover
    >
      <template #cell(view)="account" class="text-center">
        <b-icon
          variant="primary"
          icon="eye"
          @click="showAccount(account.item.id)"
          class="cursor-pointer"
        />
      </template>

      <template #cell(edit)="account" class="text-center">
        <b-icon
          variant="primary"
          icon="pencil"
          @click="editAccount(account.item.id)"
          class="cursor-pointer"
        />
      </template>
    </b-table>

    <account-edit-modal v-if="currentAccount && currentAccount.id" :accountId="currentAccount.id" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AccountEditModal from '@/components/views/accounts/AccountEditModal.vue';

export default {
  name: 'AccountsList',

  components: {
    AccountEditModal
  },

  data: () => ({
    tableFields: [
        {
          label: 'Name',
          key: 'friendlyName',
          sortable: true,
        },
        {
          label: 'ID',
          key: 'id',
        },
        {
          label: 'Weavr id',
          key: 'weavrId',
          class: 'text-center',
        },
        {
          label: '',
          key: 'view',
          class: 'text-center',
        },
        {
          label: '',
          key: 'edit',
          class: 'text-center',
        },
      ],
  }),

  computed: {
    ...mapState('accounts', ['list', 'currentAccount']),
  },

  created() {
    this.getList();
  },

  methods: {
    ...mapActions('accounts', ['getList', 'getAccount']),

    showAccount(id) {
      this.$router.push({
        name: 'account-detail',
        params: {
          id,
        }
      })
    },

    async editAccount(id) {
      await this.getAccount(id);
      this.$bvModal.show('account-edit-modal');
    },

    createAccount() {
      this.$router.push({
        name: 'account-create',
      });
    },
  }
}
</script>
