export default {
  namespaced: true,

  state: {
    loading: false,
  },

  mutations: {
    setLoading(state, payload = true) {
      state.loading = payload;
    },
  },
  actions: {},
  getters: {},
};
