<template>
  <div class="out-transfers-list">
    <b-table
      :items="outTransfersList"
      :fields="tableFields"
      striped
      hover
      @row-clicked="goToDetail"
    >
      <template #cell(creationTimestamp)="cell">
        {{ localizedTime(cell.value) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from '@/services/dayjs';

dayjs.locale('ru');

export default {
  name: 'OutTransfersList',

  data: () => ({
    tableFields: [
      {
        label: 'Country',
        key: 'beneficiaryBankCountry',
      },
      {
        label: 'Value',
        key: 'transferAmount',
      },
      {
        label: 'Currency',
        key: 'transferCurrency',
      },
      {
        label: 'Type',
        key: 'type',
      },
      {
        label: 'State',
        key: 'state',
      },
      {
        label: 'Created at',
        key: 'creationTimestamp',
      },
    ],
  }),

  computed: {
    ...mapState('transfers', ['outTransfersList']),
  },

  created() {
    this.getOutTransfersList();
  },

  methods: {
    ...mapActions('transfers', ['getOutTransfersList']),

    goToDetail({ id }) {
      this.$router.push({
        name: 'out-transfer-detail',
        params: {
          id,
        }
      })
    },

    localizedTime(time) {
      return dayjs(time).format('L LTS');
    }
  }
}
</script>
