import Vue from 'vue';
import { ACCOUNTS_ENDPOINT } from '@/constants';
import router from '@/router/';

export default {
  namespaced: true,

  state: {
    list: [],
    currentAccount: {}
  },

  mutations: {
    setList(state, payload) {
      state.list = payload
    },

    setCurrentAccount(state, payload) {
      state.currentAccount = payload;
    },
  },

  actions: {
    getList({ commit }) {
      Vue.axios.get(ACCOUNTS_ENDPOINT).then(({ data }) => {
        commit('setList', data);
      }).catch(() => {});
    },

    getAccount({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(`${ACCOUNTS_ENDPOINT}/${id}`).then(({ data }) => {
          commit('setCurrentAccount', data);
          resolve();
        }).catch(() => {
          reject();
        });
      })
    },

    editAccount(store, { id, payload, event }) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`${ACCOUNTS_ENDPOINT}/${id}`, payload)
          .then(() => {
            event.preventDefault();
            resolve();
          }).catch(() => {
          reject();
        });
      })
    },

    createAccount(store, payload) {
      Vue.axios.post(ACCOUNTS_ENDPOINT, payload).then(() => {
        router.push({
          name: 'accounts-list',
        });
      })
      .catch(error => {
        throw new Error(error);
      })
    }
  },
  getters: {}
}