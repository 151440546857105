<template>
  <div class="card-edit-limit">
    <b-form @submit.prevent>
      <b-row>
        <b-col v-for="(period, key) in periods" :key="key" cols="6">
          <b-form-group
            :label="period + ' Limit'"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Value"
                >
                  <b-form-input
                    :placeholder="`0`"
                    @change="recalcLimits"
                    type="number"
                    v-model="limits[period]['amount']"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Currency"
                >
                  <b-form-select
                    @change="recalcLimits"
                    v-model="limits[period]['currency']"
                    :options="currencies">
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'CardEditLimit',

  props: {
    id: {
      type: String,
      required: true,
    },

    cardLimitModel: {
      type: Array,
      required: true,
    }
  },

  created() {
    if (this.currentCard?.cardLimits === null || this.currentCard?.cardLimits === undefined) {
      this.currentCard.cardLimit = [];
    }

    this.periods.forEach((el) => {
      this.limits[el] = this.getPeriod(el)
    })
  },

  data: () => ({
    periods: [
      'DAILY',
      'WEEKLY',
      'MONTHLY',
      'QUARTERLY',
      'YEARLY',
      'ALWAYS',
    ],
    currencies: [
      {value: 'USD', text: 'USD'},
      {value: 'GBP', text: 'GBP'},
      {value: 'EUR', text: 'EUR'},
    ],
    limits: {},
  }),

  methods: {
    recalcLimits: function () {
      for (const period in this.limits) {
        const amount = parseInt(this.limits[period].amount)
        if (amount === 0) {
          this.deletePeriod(period);
        } else {
          this.setPeriod(period, this.limits[period])
        }
      }
    },
    deletePeriod(period) {
      for (const key in this.currentCard.cardLimit) {
        if (this.currentCard.cardLimit[key].interval === period) {
          this.currentCard.cardLimit.splice(key, 1);
        }
      }
    },
    setPeriod(period, limitData) {
      let found = false;
      for (const key in this.currentCard.cardLimit) {
        if (this.currentCard.cardLimit[key].interval === period) {
          found = true;
          this.currentCard.cardLimit[key].amount = parseInt(limitData.amount);
          this.currentCard.cardLimit[key].currency = limitData.currency;
        }
      }
      if (found === false) {
        const newLimit = {
          interval: period,
          amount: parseInt(limitData.amount),
          currency: limitData.currency,
        };

        this.currentCard.cardLimit.push(newLimit);
      }
    },
    getPeriod(period) {
      for (const key in this.cardLimitModel) {
        if (this.cardLimitModel[key].interval === period) {
          return {
            amount: this.cardLimitModel[key].amount,
            currency: this.cardLimitModel[key].currency,
          };
        }
      }

      return {
        amount: 0,
        currency: this.currentCard.currency,
      };
    },
  },

  computed: {
    ...mapState('cards', ['currentCard']),
  },
}
</script>
