<template>
  <div class="card-edit">
    <b-form @submit.prevent>
      <b-row class="mb-3">
        <b-col :cols="6">
          <b-form-group label="cardholderMobileNumber">
            <b-form-input
              placeholder="Введите cardholderMobileNumber"
              v-model="currentCard.cardholderMobileNumber"
            />
          </b-form-group>
        </b-col>
        
        <b-col :cols="6">
          <b-form-group label="friendlyName">
            <b-form-input
              placeholder="Введите friendlyName"
              v-model="currentCard.friendlyName"
            />
          </b-form-group>
        </b-col>
        
        <b-col :cols="6">
          <b-form-group label="tag">
            <b-form-input
              placeholder="Введите tag"
              v-model="currentCard.tag"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group 
        label="Billing Address"
        label-class="font-weight-bold pt-0"
      >
        <b-row>
          <b-col v-for="(field, key) in currentCard.adress" :key="key" :cols="6">
            <b-form-group
              :label="key"
            >
              <b-form-input 
                :placeholder="`Введите ${key}`"
                v-model="currentCard.adress[key]"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CardEdit',

  props: {
    id: {
      type: String,
      required: true,
    },

    cardModel: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ...mapState('cards', ['currentCard']),
  },
}
</script>