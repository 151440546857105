import Vue from 'vue';
import { TRANSFERS_ENDPOINT, OUT_TRANSFERS_ENDPOINT } from '@/constants/';
import router from '@/router';

export default {
  namespaced: true,

  state: {
    transfersList: [],
    outTransfersList: [],
    currentTransfer: {},
    currentOutTransfer: {},
  },

  mutations: {
    setTransfersList(state, payload) {
      state.transfersList = payload;
    },
    setCurrentTransfer(state, payload) { 
      state.currentTransfer = payload;
    },    
    setOutTransfersList(state, payload) {
      state.outTransfersList = payload;
    },
    setCurrentOutTransfer(state, payload) { 
      state.currentOutTransfer = payload;
    },    
  },

  actions: {
    getTransfersList({ commit }) {
      Vue.axios.get(TRANSFERS_ENDPOINT).then(({ data }) => {
        commit('setTransfersList', data);
      }).catch();
    },
    getTransfer({ commit }, id) {
      Vue.axios.get(`${TRANSFERS_ENDPOINT}/${id}`).then(({ data }) => {
        commit('setCurrentTransfer', data);
      }).catch();
    },
    createTransfer(store, payload) {
      Vue.axios.post(TRANSFERS_ENDPOINT, payload)
          .then(() => {
            router.push({
              name: 'transfers-list',
            });
          })
          .catch(error => {
            throw new Error(error);
          })
    },
    getOutTransfersList({ commit }) {
      Vue.axios.get(OUT_TRANSFERS_ENDPOINT).then(({ data }) => {
        commit('setOutTransfersList', data);
      }).catch();
    },
    getOutTransfer({ commit }, id) {
      Vue.axios.get(`${OUT_TRANSFERS_ENDPOINT}/${id}`).then(({ data }) => {
        commit('setCurrentOutTransfer', data);
      }).catch();
    },
  }
}