<template>
  <div class="cards-list">
    <b-icon icon="plus-circle-fill" variant="primary" class="h1 cursor-pointer ml-auto d-block mb-4" @click="createCard" />

    <b-table
      :items="list"
      :fields="tableFields"
      striped
      hover
    >
      <template #cell(view)="card" class="text-center">
        <b-icon
          variant="primary"
          icon="eye"
          @click="showCard(card.item.id)"
          class="cursor-pointer"
        />
      </template>

      <template #cell(info)="card" class="text-center">
        <b-icon
          variant="primary"
          icon="info-circle"
          @click="showCardInfo(card.item)"
          class="cursor-pointer"
        />
      </template>

      <template #cell(edit)="card" class="text-center">
        <b-icon
          variant="primary"
          icon="pencil"
          @click="editCard(card.item.id)"
          class="cursor-pointer"
        />
      </template>

      <template #cell(editLimit)="card" class="text-center">
        <b-icon
          variant="primary"
          icon="cash"
          @click="editCardLimit(card.item.id)"
          class="cursor-pointer"
        />
      </template>

      <template #cell(block)="card">
        <b-icon
          :variant="isLocked(card) ? 'danger' : 'primary'"
          :icon="isLocked(card) ? 'unlock' : 'lock'"
          @click="block({id: card.item.id, block: isLocked(card)})"
          class="cursor-pointer"
        />
      </template>
      <template #cell(delCard)="card">
        <b-icon
            variant="primary"
            icon="trash"
            @click="delCard(card.item.id)"
            class="cursor-pointer"
        />
      </template>
    </b-table>

    <card-edit-modal v-if="currentCard && currentCard.id" :cardId="currentCard.id" />
    <card-edit-limit-modal v-if="currentCard && currentCard.id" :cardId="currentCard.id" />
    <card-info-modal />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import CardEditModal from '@/components/views/cards/CardEditModal.vue';
import CardEditLimitModal from '@/components/views/cards/CardEditLimitModal.vue';
import CardInfoModal from '@/components/views/cards/CardInfoModal.vue';

export default {
  name: 'CardsList',

  components: {
    CardEditModal,
    CardEditLimitModal,
    CardInfoModal,
  },

  computed: {
    ...mapState('cards', [
      'list',
      'currentCard',
    ]),

    tableFields() {
      return [
        {
          label: 'Name',
          key: 'friendlyName',
          sortable: true,
        },
        {
          label: 'Currency',
          key: 'currency',
          sortable: true,
        },
        {
          label: 'Phone',
          key: 'cardholderMobileNumber',
        },
        {
          label: 'Balance',
          key: 'actualBalance',
        },
        {
          label: '',
          key: 'view',
          class: 'text-center',
        },
        {
          label: '',
          key: 'info',
          class: 'text-center',
        },
        {
          label: '',
          key: 'edit',
          class: 'text-center',
        },
        {
          label: '',
          key: 'editLimit',
          class: 'text-center',
        },
        {
          label: '',
          key: 'block',
          class: 'text-center',
        },
        {
          label: '',
          key: 'delCard',
          class: 'text-center',
        }
      ]
    }
  },

  created() {
    this.getList();
  },

  methods: {
    ...mapMutations('cards', ['setCurrentCard']),
    ...mapActions('cards', ['getList', 'setCardBlockState', 'getCard', 'deleteCard']),

    showCard(id) {
      this.$router.push({
        name: 'card-detail',
        params: {
          id,
        }
      })
    },

    showCardInfo(card) {
      this.setCurrentCard(card);
      this.$bvModal.show('card-info-modal');
    },

    async editCard(id) {
      await this.getCard(id);
      this.$bvModal.show('card-edit-modal');
    },

    async editCardLimit(id) {
      await this.getCard(id);
      this.$bvModal.show('card-edit-limit-modal');
    },

    block({id, block}) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure to ${block ? 'block' : 'unblock'} the card?`, {
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          centered: true,
        })
        .then(ok => {
          if (ok) {
            this.setCardBlockState({
              id,
              block
            })
          }
        })
        .catch(err => {
          console.error(err);
        })
    },

    delCard(id) {
      this.$bvModal
          .msgBoxConfirm('Are you sure delete the card?', {
            okTitle: 'Confirm',
            cancelTitle: 'Cancel',
            centered: true,
          })
          .then(ok => {
            if (ok) {
              this.deleteCard({id})
            }
          })
          .catch(err => {
            console.error(err);
          })
    },

    createCard() {
      this.$router.push({
        name: 'card-create'
      })
    },

    isLocked(card) {
      return card.item.state === 'ACTIVE';
    },
  }
}
</script>
