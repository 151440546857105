<template>
  <div class="card-detail" v-if="isPageReady">
    <b-form>
      <b-row class="mb-3">
        <b-col v-for="(value, key) in cardPrimitiveFields" :key="key" :cols="6">
          <b-form-group :label="key">
            <b-form-input
              disabled
              :value="value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      
      <b-row v-for="(object, index) in cardObjects" :key="index" class="mb-3">
        <b-col v-for="(value, key) in object" :key="key" :cols="6">
          <b-form-group :label="key">
            <b-form-input
              disabled
              :value="value"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <b-button class="mr-2" @click="goToList">Назад</b-button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'CardDetail',

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState('cards', ['currentCard']),

    isPageReady() {
      return !!(Object.keys(this.currentCard).length);
    },

    cardPrimitiveFields() {
      return Object.entries(this.currentCard).reduce((fields, [key, value]) => {
        if (typeof value !== 'object') {
          fields[key] = value;
        }
        return fields;
      }, {});
    },
    
    cardObjects() {
      return Object.values(this.currentCard).filter(o => typeof o === 'object');
    },
  },


  created() {
    this.getCard(this.id);
  },

  methods: {
    ...mapActions('cards', ['getCard']),

    goToList() {
      this.$router.push({
        name: 'cards-list',
      });
    },
  },
}
</script>