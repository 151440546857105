<template>
  <b-modal
    id="card-info-modal"
    :title="`Show card number`"
    centered
    hide-footer
    @show="onModalShowHandler"
  >
    <div class="card-info-data">
      <div ref="cardNumber" />
      <div ref="cvv" />
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import dayjs from '@/services/dayjs';

export default {
  name: 'CardInfoModal',

  data: () => ({
    formActive: false,
    formSuccess: false,
    cardNumber: '',
    cvv: '',
  }),

  computed: {
    ...mapState('cards', ['currentCard']),
    ...mapState('credentials', ['credentials']),
    ...mapGetters('credentials', ['uiKey', 'expires', 'userAuthToken']),

    isCredentialsExpired() {
      return dayjs().diff(dayjs.unix(this.expires), 'years') >= 0;
    },
  },

  methods: {
    ...mapMutations('cards', ['setCurrentCard']),
    ...mapActions('credentials', ['getCredentials']),

    async onModalShowHandler() {
      if (!this.uiKey || this.isCredentialsExpired) {
        await this.getCredentials();
      }

      const weavr = window.OpcUxSecureClient;
      if (!weavr.consumer_kyc().uiKey) {
        weavr.init(this.uiKey);
      }

      weavr.associate(`Bearer ${this.userAuthToken}`, () => {
        console.log('associate success');

        const cardNumber = weavr.span('cardNumber', this.currentCard.cardNumber);
        const cvv = weavr.span('cardNumber', this.currentCard.cvv);
        cardNumber.mount(this.$refs.cardNumber);
        cvv.mount(this.$refs.cvv);

        this.formActive = true;
      }, (error) => {
        console.error('associate failed ' + error);
      });
    },
  },

  beforeDestroy() {
    this.setCurrentCard({});
  }
 }
</script>

<style lang="scss">
.card-info-data {
  padding: 10px;
  margin: 12px 0;
  background: lighten(black, 95%);
  border-radius: 3px;
}
</style>
