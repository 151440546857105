<template>
  <div class="card-edit">
    <b-form @submit.prevent>
      <b-row class="mb-3">        
        <b-col :cols="6">
          <b-form-group label="friendlyName">
            <b-form-input
              placeholder="Введите friendlyName"
              v-model="currentAccount.friendlyName"
            />
          </b-form-group>
        </b-col>
      </b-row>
        
      <b-row class="mb-3">        
        <b-col :cols="6">
          <b-form-group label="tag">
            <b-form-input
              placeholder="Введите tag"
              v-model="currentAccount.tag"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AccountEdit',

  props: {
    id: {
      type: String,
      required: true,
    },

    accountModel: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ...mapState('accounts', ['currentAccount']),
  },
}
</script>