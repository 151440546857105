import Vue from 'vue';
import router from '@/router/';
import { CARDS_ENDPOINT } from '@/constants';

export default {
  namespaced: true,

  state: {
    list: [],
    currentCard: {},
  },

  mutations: {
    setList(state, payload) {
      state.list = payload
    },

    setCurrentCard(state, payload) {
      state.currentCard = payload;
    },
  },

  actions: {
    getList({ commit }) {
      Vue.axios.get(CARDS_ENDPOINT).then(({ data }) => {
        commit('setList', data);
      }).catch(() => {});
    },

    getCard({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(`${CARDS_ENDPOINT}/${id}`).then(({ data }) => {
          commit('setCurrentCard', data);
          resolve();
        }).catch(() => {
          reject();
        });
      })
    },

    setCardBlockState({ dispatch }, { id, block }) {
      const action = block ? 'block' : 'unblock';
      Vue.axios.post(`${CARDS_ENDPOINT}/${id}/${action}`).then(() => {
        dispatch('getList');
      }).catch();
    },

    deleteCard({ dispatch }, { id }) {
      Vue.axios.delete(`${CARDS_ENDPOINT}/${id}/delete`).then(() => {
        dispatch('getList');
      }).catch();
    },

    editCard(store, {id, payload, event}) {
      return new Promise((resolve, reject) => {
        Vue.axios.patch(`${CARDS_ENDPOINT}/${id}`, payload)
          .then(() => {
            event.preventDefault();
            resolve();
          }).catch(() => {
            reject();
        });
      });
    },

    editCardLimit(store, { id, payload, event }) {
      Vue.axios.put(`${CARDS_ENDPOINT}/${id}/spend_rules`, payload)
        .catch(() => {
          event.preventDefault();
        });
    },

    createCard(store, payload) {
      Vue.axios.post(CARDS_ENDPOINT, payload)
        .then(() => {
          router.push({
            name: 'cards-list',
          });
        })
        .catch(error => {
          throw new Error(error);
        })
    }
  },
}
