<template>
  <div class="transfer-create">
    <b-form @submit.prevent="submit">
      <b-row class="mb-5">
        <b-col
            v-for="(value, field) in transferPrimitiveFields"
            :key="field"
            :cols="6"
        >
          <b-form-group :label="field">
            <b-input
                v-model="form[field]"
                :placeholder="`Enter field value  ${field}`"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-form-group
          label="Destination"
          label-class="font-weight-bold mb-3 pt-0"
      >
        <b-row>
          <b-col>
            <b-form-group label="id">
              <b-input
                  v-model.number="form.destination.id"
                  :placeholder="`Enter field value  destination id`"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="type">
              <b-input
                  v-model="form.destination.type"
                  :placeholder="`Enter field value  destination type`"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
          label="Destination Amount"
          label-class="font-weight-bold mb-3 pt-0"
      >
        <b-row>
          <b-col>
            <b-form-group label="id">
              <b-input
                  v-model.number="form.destinationAmount.amount"
                  :placeholder="`Enter field value  destinationAmount amount`"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="type">
              <b-input
                  v-model="form.destinationAmount.currency"
                  :placeholder="`Enter field value  destinationAmount currency`"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
          label="Source"
          label-class="font-weight-bold mb-3 pt-0"
      >
        <b-row>
          <b-col>
            <b-form-group label="id">
              <b-input
                  v-model.number="form.source.id"
                  :placeholder="`Enter field value  source id`"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="type">
              <b-input
                  v-model="form.source.type"
                  :placeholder="`Enter field value  source type`"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <b-button class="mr-2" @click="goToList">Cancel</b-button>
      <b-button variant="primary" type="submit">Save</b-button>
    </b-form>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'TransferCreate',

  data: () => ({
    form: {
      destination: {
        id: '',
        type: '',
      },
      destinationAmount: {
        amount: '',
        currency: '',
      },
      source: {
        id: '',
        type: '',
      },
      state: '',
      tag: ''
    }
  }),

  computed: {
    transferPrimitiveFields() {
      return Object.entries(this.form).reduce((fields, [key, value]) => {
        if (typeof value !== 'object') {
          fields[key] = value;
        }
        return fields;
      }, {});
    },
  },

  methods: {
    ...mapActions('transfers', ['createTransfer']),

    submit() {
      this.createTransfer(this.form);
    },

    goToList() {
      this.$router.push({
        name: 'transfers-list',
      })
    }
  }
}
</script>
