<template>
  <b-modal
    id="card-edit-modal"
    class="card-edit-modal"
    size="xl"
    title="Edit card"
    centered
    ok-title="Save"
    cancel-title="Cancel"
    @ok="edit($event)"
  >
    <card-edit :id="cardId" :cardModel="cardModel" />
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CardEdit from './CardEdit.vue';

export default {
  name: 'CardEditModal',

  components: {
    CardEdit
  },

  props: {
    cardId: {
      type: String,
      required: true,
    }
  },

  computed: {
    ...mapState('cards', ['currentCard']),

    cardModel() {
      return {
        billingAddress: this.currentCard.adress,
        cardholderMobileNumber: this.currentCard.cardholderMobileNumber,
        friendlyName: this.currentCard.friendlyName,
        tag: this.currentCard.tag,
      }
    }
  },

  methods: {
    ...mapActions('cards', [ 'editCard', 'getList' ]),

    edit($event) {
      this.editCard({
        id: this.cardId,
        payload: this.cardModel,
        event: $event
      }).then(() => {
        this.getList();
      })
    }
  }
}
</script>
