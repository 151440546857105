<template>
  <div class="account-detail">
    <b-form>
      <b-row class="mb-3">
        <b-col v-for="(value, key) in currentAccount" :key="key" :cols="6">
          <b-form-group :label="key">
            <b-form-input
              disabled
              :value="value"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <b-button class="mr-2" @click="goToList">Назад</b-button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AccountDetail',

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState('accounts', ['currentAccount']),
  },


  created() {
    this.getAccount(this.id);
  },

  methods: {
    ...mapActions('accounts', ['getAccount']),

    goToList() {
      this.$router.push({
        name: 'accounts-list',
      });
    },
  }
}
</script>