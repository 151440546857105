import Vue from 'vue';
import Vuex from 'vuex';
import loading from './modules/loading';
import auth from './modules/auth';
import cards from './modules/cards';
import accounts from './modules/accounts';
import transfers from './modules/transfers';
import credentials from './modules/credentials';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    loading,
    cards,
    accounts,
    transfers,
    credentials,
  },
});
