<template>
  <div class="navigation">
    <nav class="navigation-nav" v-if="isUserLoggedIn">
      <template v-for="(item, index) in activeLinks">
        <b-row :key="index" class="navigation-nav-row">
          <b-link
            v-if="item.route"
            :to="{ name: item.route }"
            class="navigation-nav-link"
            :class="{ 'navigation-nav-link--disable': item.disable }"
            active-class="navigation-nav-link--active"
            :exact="true"
            append
            @click.prevent="callCallback(item.callback)"
          >
            {{ item.title }}
          </b-link>
          <span
            v-else
            class="navigation-nav-link"
            :class="{ 'navigation-nav-link--disable': item.disable }"
            @click.prevent="callCallback(item.callback)"
          >
            {{ item.title }}
          </span>
        </b-row>
      </template>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { NAVIGATION } from '@/constants/navigation';

export default {
  name: 'Navigation',
  
  data() {
    return {
      navigation: NAVIGATION,
    };
  },
  
  computed: {
    ...mapGetters('auth', ['isUserLoggedIn']),

    activeLinks() {
      return this.navigation.filter((item) => !item.disable);
    },
  },
  
  methods: {
    callCallback(callback = null) {
      if (callback) this.$emit(callback);
    },
  },
};
</script>

<style lang="scss">
.navigation {
  grid-area: navigation;
  grid-row-end: -1;
  background-color: var(--color-bg-sidebar);
  padding: 40px 20px;
  font-size: 18px;

  &-logo {
    .logo {
      margin: 0;
      img {
        max-width: 127px;
      }
    }
  }

  &-nav {
    &-row {
      margin: 30px 0;
    }

    &-link {
      color: var(--link-color);
      text-decoration: none;
      padding: 0;
      cursor: pointer;
      font-weight: 500;

      &:hover, &--active {
        color: var(--color-hover);
        & > * {
          fill: var(--color-hover);
        }
      }
    }
  }
}
</style>