import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import app from '@/main';

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');

Vue.axios.interceptors.request.use(
  (config) => {
    app.$store.commit('loading/setLoading');
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
)

Vue.axios.interceptors.response.use(
  (response) => {
    // Временный костыль для обработки ошибок с бэкенда
    const error = response?.data?.message.toLowerCase();
    app.$store.commit('loading/setLoading', false);
    if (error?.includes('error')) {      
      Vue.$toast.error(response.data.message);

      return Promise.reject(response.data.message);
    }
    return response.data;
  },
  (error) => {
    app.$store.commit('loading/setLoading', false);
    const { data, status } = error?.response;
    const tokenIsExpired = data?.message === 'Token is expired';

    if (data.code === 401 && tokenIsExpired) {
      app.$store.dispatch('auth/refreshToken').then(() => {
        return Vue.axios.request({
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: app.$store.state.auth.token,
          }
        });
      });
      app.$forceUpdate();
      return Promise.resolve();
    } else if (app.$route.name !== 'login' && status === 401) {
      localStorage.removeItem('user-token');
      app.$store.dispatch('auth/updateToken', '');
      app.$router.push({
        name: 'login',
        params: { redirect: app.$route.path },
      });
      return;
    }
    
    Vue.$toast.error(error.response.data.message);
    app.$store.commit('loading/setLoading', false);
    return Promise.reject(error);
  }
);
