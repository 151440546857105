<template>
  <b-modal
    id="card-edit-limit-modal"
    class="card-edit-limit-modal"
    size="xl"
    :title="`Edit card limit`"
    centered
    ok-title="Save"
    cancel-title="Cancel"
    @ok="edit($event)"
  >
    <card-edit-limit :id="cardId" :cardLimitModel="cardLimitModel" />
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CardEditLimit from './CardEditLimit.vue';

export default {
  name: 'CardEditLimitModal',

  components: {
    CardEditLimit
  },

  props: {
    cardId: {
      type: String,
      required: true,
    }
  },

  computed: {
    ...mapState('cards', ['currentCard']),

    cardLimitModel() {
      return this.currentCard.cardLimit ?? [];
    },
  },

  methods: {
    ...mapActions('cards', [ 'editCardLimit' ]),

    edit($event) {
      this.editCardLimit({
        id: this.cardId,
        payload: {
          cardLimit: this.currentCard.cardLimit
        },
        event: $event
      })
    }
  }
}
</script>
