<template>
  <div class="login-page">
<!--    <h1 class="mb-5">Авторизация</h1>-->

    <b-row>
      <b-col :cols="6">
        <b-form @submit.prevent="submit">
          <b-form-group label="Email">
            <b-form-input
              id="login"
              type="email"
              placeholder="enter Email"
              v-model="form.email"
              required
            />
          </b-form-group>

          <b-form-group label="Password">
            <b-form-input
              id="password"
              type="password"
              placeholder="enter password"
              v-model="form.password"
              required
            />
          </b-form-group>
          <b-button type="submit" variant="primary" :disabled="$v.$invalid">Login</b-button>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapActions } from 'vuex';

export default {
  name: 'LoginPage',

  mixins: [
    validationMixin
  ],

  data: () => ({
    form: {
      email: '',
      password: '',
    },
  }),

  methods: {
    ...mapActions('auth', ['login']),

    submit() {
      const { email, password } = this.form;
      this.login({ email, password }).then(() => {
        this.$router.push({ name: 'index'});
      });
    },
  },

  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      }
    }
  }
}
</script>
