export const AuthGuard = {
  isLoggedIn(to, from, next) {
    setTimeout(() => {
      const token = localStorage.getItem('user-token')
      if (token && token.length > 0) {
        next();
      } else {
        next('/login');
      }
    }, 350);
  },

  goToMainPage(to, from, next) {
    next({ name: 'home' });
  },
};