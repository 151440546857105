<template>
  <div class="account-create">
    <b-form @submit.prevent="submit">
      <b-row class="mb-5">
        <b-col
          v-for="(value, field) in form"
          :key="field"
          :cols="6"
        >
          <b-form-group :label="field">
            <b-input
              v-model="form[field]"
              :placeholder="`Enter field value  ${field}`"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button class="mr-2" @click="goToList">Cancel</b-button>
      <b-button variant="primary" type="submit">Save</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AccountCreate',

  data: () => ({
    form: {
      currency: '',
      friendlyName: '',
      tag: '',
    }
  }),

  methods: {
    ...mapActions('accounts', ['createAccount']),

    submit() {
      this.createAccount(this.form);
    },

    goToList() {
      this.$router.push({
        name: 'accounts-list',
      })
    }
  }
}
</script>
